import { Component } from '@angular/core';

@Component({
    selector: 'app-privacy-policy-en',
    templateUrl: './privacy-policy-en.component.html',
    styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyEnComponent {
  constructor() {}
}
